<script>
	import Base from '@backend/Base.vue';

	export default {
		name: "StaticSeo",
		extends: Base,
		data() {
			return {
				Name: "StaticSeo",
				mrLevel: {},
				titleLength:0,
				descLength:0,
				maxTitle:60,
				maxDesc:160,
        dataLength: 0
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
      this.moduleRedirect(false);
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
      'row.ss_title'(v){
        this.titleLength = v.length
      },
      'row.ss_desc'(v){
        this.descLength = v.length
      },
			'row.ss_keyword'(v) {
				if (v.length > 0) {
					$("[error='ss_keyword']").html("");
				}
			}
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle :no-add-item="true"></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row gutter-10">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div v-if="dataLength > 0" class="col-9 col-lg-3 col-sm-5 ml-auto">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Cari...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div v-if="dataLength > 0" class="col-3 col-sm-auto">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										Halaman
									</th>
									<th>{{fields.ss_title}}</th>
									<th>{{fields.ss_desc}}</th>
									<th class="text-center" width="120px" v-if="moduleRole('Edit')">Aksi</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{(v.ss_page||'-')}}</td>
									<td>{{(v.ss_title||'-')}}</td>
									<td>{{(v.ss_desc||'-')}}</td>
									<td class="btn-action text-center" v-if="moduleRole('Edit')">
										<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Ubah'"><i
												class="ti-marker-alt"></i></router-link>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h5 class="tc">{{NotFound}}</h5>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="justify-content-end m-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{row.id?"Ubah":"Tambah"}} {{ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-12">
							<BoField name="ss_page" :attr="{readonly:'readonly'}" v-model="row.ss_page"></BoField>
							<BoField v-bind="validation('ss_title')" class="mb-0" name="ss_title" v-model="row.ss_title"></BoField>
							<small class="mb-2 d-inline-block text-info">{{maxTitle-titleLength}} character remaining </small> 
							<BoField class="mb-0" name="ss_keyword">
								<TagsInput name="ss_keyword" v-model="row.ss_keyword" :attr="validation('ss_keyword')" :placeholder="'Ketik dan enter'"></TagsInput>
							</BoField>
							<BoField class="mb-0" name="ss_desc">
								<textarea name="ss_desc" v-bind="validation('ss_desc')" v-model="row.ss_desc" rows="4"  class="form-control"></textarea>
							</BoField>
							<small class="mb-2 d-inline-block text-info">{{maxDesc-descLength}} character remaining </small> 
						</div>
						<div class="col-12">
							<div class="text-right">
                <router-link
                  :to="{name: $route.name}"
                  class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                >
                  Kembali
                </router-link>
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-1e btn-loading">{{$route.params.id=='add'?'Tambah':'Ubah'}}</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>